import React from 'react';
import { Tooltip } from 'react-svg-tooltip';

function SvgWithBackgroundImage() {
  const triggerRefHeatPump1 = React.createRef();
  const triggerRefHeatPump2 = React.createRef();
  const triggerRefFirePlace = React.createRef();
  const triggerRefPropaneTank = React.createRef();
  const triggerRefSpaceHeater = React.createRef();
  const triggerRefWaterHeater = React.createRef();
  const triggerRefBBQ = React.createRef();
  const triggerRefFurnaceBoiler = React.createRef();
  const triggerRefGasRange = React.createRef();
  const triggerRefGenerator = React.createRef();

  return (
    <>
      <div className="propaneHome">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 612 410"
        >
          <g id="label-inactive-dryer">
            <g>
              <polygon
                className="st0"
                points="524.01,350.08 497.42,322.08 517.94,355.78 517.94,365.74 600.08,365.74 600.08,350.08 		"
              />
              <g>
                <path
                  className="st1"
                  d="M527.65,360.51c-1.65,0-2.87-1.27-2.87-2.88v-0.02c0-1.59,1.2-2.89,2.92-2.89c1.06,0,1.69,0.35,2.21,0.86
  				l-0.78,0.9c-0.43-0.39-0.87-0.63-1.43-0.63c-0.94,0-1.62,0.78-1.62,1.74v0.02c0,0.96,0.66,1.76,1.62,1.76
  				c0.64,0,1.03-0.26,1.47-0.66l0.78,0.79C529.37,360.12,528.73,360.51,527.65,360.51z"
                />
                <path
                  className="st1"
                  d="M530.93,360.41v-5.6h1.23v4.48h2.79v1.12H530.93z"
                />
                <path
                  className="st1"
                  d="M538.28,360.51c-1.73,0-2.97-1.29-2.97-2.88v-0.02c0-1.59,1.25-2.89,2.98-2.89s2.97,1.29,2.97,2.88v0.02
  				C541.26,359.2,540.01,360.51,538.28,360.51z M539.97,357.61c0-0.96-0.7-1.76-1.7-1.76c-0.99,0-1.68,0.78-1.68,1.74v0.02
  				c0,0.96,0.7,1.76,1.7,1.76s1.68-0.78,1.68-1.74V357.61z"
                />
                <path
                  className="st1"
                  d="M544.66,355.95v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H544.66z"
                />
                <path
                  className="st1"
                  d="M550.81,360.41v-2.25h-2.27v2.25h-1.23v-5.6h1.23v2.21h2.27v-2.21h1.23v5.6H550.81z"
                />
                <path
                  className="st1"
                  d="M553.38,360.41v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H553.38z"
                />
                <path
                  className="st1"
                  d="M560.74,360.49c-0.85,0-1.7-0.3-2.38-0.9l0.73-0.87c0.5,0.42,1.03,0.68,1.67,0.68c0.5,0,0.81-0.2,0.81-0.53
  				v-0.02c0-0.31-0.19-0.47-1.13-0.71c-1.13-0.29-1.85-0.6-1.85-1.71v-0.02c0-1.01,0.82-1.69,1.96-1.69c0.82,0,1.51,0.26,2.08,0.71
  				l-0.64,0.93c-0.5-0.34-0.98-0.55-1.46-0.55c-0.47,0-0.72,0.22-0.72,0.49v0.02c0,0.37,0.24,0.49,1.21,0.74
  				c1.13,0.3,1.78,0.7,1.78,1.68v0.02C562.79,359.87,561.94,360.49,560.74,360.49z"
                />
                <path
                  className="st1"
                  d="M568.45,360.41h-2.18v-5.6h2.18c1.76,0,2.97,1.21,2.97,2.78v0.02C571.42,359.19,570.21,360.41,568.45,360.41
  				z M570.13,357.61c0-0.99-0.68-1.69-1.69-1.69h-0.95v3.37h0.95c1.01,0,1.69-0.68,1.69-1.67V357.61z"
                />
                <path
                  className="st1"
                  d="M575.92,360.41l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H575.92z M575.94,356.72c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61H575c0.6,0,0.94-0.32,0.94-0.79
  				V356.72z"
                />
                <path
                  className="st1"
                  d="M580.91,358.18v2.23h-1.23v-2.21l-2.15-3.39h1.44l1.33,2.25l1.36-2.25h1.4L580.91,358.18z"
                />
                <path
                  className="st1"
                  d="M583.83,360.41v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H583.83z"
                />
                <path
                  className="st1"
                  d="M592.59,360.41l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H592.59z M592.62,356.72c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V356.72z"
                />
              </g>
            </g>
          </g>
          <g id="label-inactive-pool-heater">
            <g>
              <polygon
                className="st0"
                points="606,292.98 589.68,312.66 524.02,312.66 524.02,328.33 594.75,328.33 594.75,319.08 		"
              />
              <g>
                <path
                  className="st1"
                  d="M532.27,321.32h-0.94v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C534.54,320.64,533.53,321.32,532.27,321.32z M533.29,319.36c0-0.55-0.38-0.85-1-0.85h-0.95v1.71h0.98
  				c0.62,0,0.98-0.37,0.98-0.85V319.36z"
                />
                <path
                  className="st1"
                  d="M538.17,323.09c-1.73,0-2.97-1.29-2.97-2.88v-0.02c0-1.59,1.26-2.89,2.98-2.89c1.73,0,2.97,1.29,2.97,2.88
  				v0.02C541.15,321.79,539.9,323.09,538.17,323.09z M539.87,320.2c0-0.96-0.7-1.76-1.69-1.76c-0.99,0-1.68,0.78-1.68,1.74v0.02
  				c0,0.96,0.7,1.76,1.7,1.76s1.68-0.78,1.68-1.74V320.2z"
                />
                <path
                  className="st1"
                  d="M544.97,323.09c-1.73,0-2.97-1.29-2.97-2.88v-0.02c0-1.59,1.26-2.89,2.98-2.89c1.73,0,2.97,1.29,2.97,2.88
  				v0.02C547.95,321.79,546.7,323.09,544.97,323.09z M546.67,320.2c0-0.96-0.7-1.76-1.69-1.76c-0.99,0-1.68,0.78-1.68,1.74v0.02
  				c0,0.96,0.7,1.76,1.7,1.76s1.68-0.78,1.68-1.74V320.2z"
                />
                <path
                  className="st1"
                  d="M549.05,322.99v-5.6h1.23v4.48h2.79v1.12H549.05z"
                />
                <path
                  className="st1"
                  d="M559.91,322.99v-2.25h-2.27v2.25h-1.23v-5.6h1.23v2.21h2.27v-2.21h1.23v5.6H559.91z"
                />
                <path
                  className="st1"
                  d="M562.48,322.99v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H562.48z"
                />
                <path
                  className="st1"
                  d="M572.01,322.99l-0.51-1.25h-2.37l-0.51,1.25h-1.25l2.4-5.64h1.14l2.4,5.64H572.01z M570.31,318.84
  				l-0.74,1.81h1.49L570.31,318.84z"
                />
                <path
                  className="st1"
                  d="M575.98,318.53v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H575.98z"
                />
                <path
                  className="st1"
                  d="M578.63,322.99v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H578.63z"
                />
                <path
                  className="st1"
                  d="M587.39,322.99l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H587.39z M587.41,319.31c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V319.31z"
                />
              </g>
            </g>
          </g>
          <g id="label-inactive-fridge">
            <g>
              <polygon
                className="st0"
                points="383.67,225.98 367.58,206.18 367.58,195.49 288.69,195.49 288.69,211.16 361.4,211.16 		"
              />
              <g>
                <path
                  className="st1"
                  d="M299.17,205.83l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H299.17z M299.2,202.14c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V202.14z"
                />
                <path
                  className="st1"
                  d="M301.56,205.83v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H301.56z"
                />
                <path
                  className="st1"
                  d="M308.15,201.35v1.19h2.67v1.12h-2.67v2.17h-1.23v-5.6h4.26v1.12H308.15z"
                />
                <path
                  className="st1"
                  d="M315.57,205.83l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H315.57z M315.59,202.14c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V202.14z"
                />
                <path
                  className="st1"
                  d="M318.01,205.83v-5.6h1.23v5.6H318.01z"
                />
                <path
                  className="st1"
                  d="M323.33,205.92c-1.74,0-2.94-1.22-2.94-2.88v-0.02c0-1.59,1.24-2.89,2.93-2.89c1.01,0,1.62,0.27,2.2,0.77
  				l-0.78,0.93c-0.43-0.36-0.82-0.57-1.46-0.57c-0.9,0-1.61,0.79-1.61,1.74v0.02c0,1.02,0.7,1.78,1.7,1.78
  				c0.45,0,0.85-0.11,1.16-0.34v-0.8h-1.24v-1.06h2.43v2.43C325.15,205.52,324.36,205.92,323.33,205.92z"
                />
                <path
                  className="st1"
                  d="M326.91,205.83v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H326.91z"
                />
                <path
                  className="st1"
                  d="M335.67,205.83l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H335.67z M335.69,202.14c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V202.14z"
                />
                <path
                  className="st1"
                  d="M342.22,205.83l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.13l2.4,5.64H342.22z M340.52,201.67
  				l-0.74,1.81h1.49L340.52,201.67z"
                />
                <path
                  className="st1"
                  d="M346.19,201.37v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H346.19z"
                />
                <path
                  className="st1"
                  d="M351.32,205.92c-1.73,0-2.97-1.29-2.97-2.88v-0.02c0-1.59,1.25-2.89,2.98-2.89c1.73,0,2.97,1.29,2.97,2.88
  				v0.02C354.3,204.62,353.04,205.92,351.32,205.92z M353.01,203.03c0-0.96-0.7-1.76-1.7-1.76c-0.99,0-1.68,0.78-1.68,1.74v0.02
  				c0,0.96,0.7,1.76,1.7,1.76c0.99,0,1.68-0.78,1.68-1.74V203.03z"
                />
                <path
                  className="st1"
                  d="M358.8,205.83l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H358.8z M358.82,202.14c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V202.14z"
                />
              </g>
            </g>
          </g>
          <g
            id="label-generator"
            className="svgTextContainer"
            ref={triggerRefGenerator}
          >
            <g>
              <path
                className="st2"
                d="M597.1,216c-2.79,0-5.32,1.08-7.22,2.83h-63.2v15.67h29.45l3.44,17.76l3.5-17.76h26.81
  			c1.9,1.75,4.43,2.83,7.22,2.83c5.89,0,10.67-4.78,10.67-10.67S603,216,597.1,216z"
              />
              <g>
                <path
                  className="st1"
                  d="M536.47,229.26c-1.74,0-2.94-1.22-2.94-2.88v-0.02c0-1.59,1.24-2.89,2.93-2.89c1.01,0,1.62,0.27,2.2,0.77
  				l-0.78,0.93c-0.43-0.36-0.82-0.57-1.46-0.57c-0.9,0-1.61,0.79-1.61,1.74v0.02c0,1.02,0.7,1.78,1.7,1.78
  				c0.45,0,0.85-0.11,1.16-0.34V227h-1.24v-1.06h2.43v2.43C538.29,228.86,537.49,229.26,536.47,229.26z"
                />
                <path
                  className="st1"
                  d="M540.05,229.16v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H540.05z"
                />
                <path
                  className="st1"
                  d="M549.33,229.16l-2.71-3.56v3.56h-1.22v-5.6h1.14l2.62,3.45v-3.45h1.22v5.6H549.33z"
                />
                <path
                  className="st1"
                  d="M551.73,229.16v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H551.73z"
                />
                <path
                  className="st1"
                  d="M560.49,229.16l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H560.49z M560.51,225.48c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V225.48z"
                />
                <path
                  className="st1"
                  d="M567.04,229.16l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.13l2.4,5.64H567.04z M565.34,225.01
  				l-0.74,1.81h1.49L565.34,225.01z"
                />
                <path
                  className="st1"
                  d="M571,224.7v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H571z"
                />
                <path
                  className="st1"
                  d="M576.13,229.26c-1.73,0-2.97-1.29-2.97-2.88v-0.02c0-1.59,1.25-2.89,2.98-2.89c1.73,0,2.97,1.29,2.97,2.88
  				v0.02C579.12,227.96,577.86,229.26,576.13,229.26z M577.83,226.37c0-0.96-0.7-1.76-1.7-1.76c-0.99,0-1.68,0.78-1.68,1.74v0.02
  				c0,0.96,0.7,1.76,1.7,1.76c0.99,0,1.68-0.78,1.68-1.74V226.37z"
                />
                <path
                  className="st1"
                  d="M583.61,229.16l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H583.61z M583.64,225.48c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V225.48z"
                />
              </g>
              <path
                className="st1"
                d="M596.3,224.35h1.61V223h-1.61V224.35z M596.34,230.33h1.53v-5.39h-1.53V230.33z M597.1,219.66
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C604.1,222.8,600.96,219.66,597.1,219.66z M597.1,232.66c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S600.41,232.66,597.1,232.66z"
              />
            </g>
          </g>
          <g id="label-bbq" className="svgTextContainer" ref={triggerRefBBQ}>
            <g>
              <path
                className="st2"
                d="M552.85,194.58c-2.79,0-5.32,1.08-7.22,2.83h-55.45v15.67h14.43l3.96,20.43l4.03-20.43h33.03
  			c1.9,1.75,4.43,2.83,7.22,2.83c5.89,0,10.67-4.78,10.67-10.67S558.75,194.58,552.85,194.58z"
              />
              <g>
                <path
                  className="st1"
                  d="M498.69,207.74h-2.66v-5.6h2.6c1.14,0,1.85,0.57,1.85,1.45v0.02c0,0.63-0.34,0.98-0.74,1.21
  				c0.65,0.25,1.05,0.62,1.05,1.38v0.02C500.78,207.23,499.95,207.74,498.69,207.74z M499.25,203.8c0-0.37-0.29-0.58-0.81-0.58
  				h-1.21v1.18h1.13c0.54,0,0.89-0.18,0.89-0.59V203.8z M499.56,206.04c0-0.38-0.28-0.61-0.91-0.61h-1.42v1.23h1.46
  				c0.54,0,0.87-0.19,0.87-0.61V206.04z"
                />
                <path
                  className="st1"
                  d="M505.97,207.74l-0.51-1.25h-2.37l-0.51,1.25h-1.25l2.4-5.64h1.13l2.4,5.64H505.97z M504.27,203.59
  				l-0.74,1.81h1.49L504.27,203.59z"
                />
                <path
                  className="st1"
                  d="M511.52,207.74l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H511.52z M511.54,204.06c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V204.06z"
                />
                <path
                  className="st1"
                  d="M516.57,207.74h-2.66v-5.6h2.6c1.14,0,1.85,0.57,1.85,1.45v0.02c0,0.63-0.34,0.98-0.73,1.21
  				c0.65,0.25,1.05,0.62,1.05,1.38v0.02C518.66,207.23,517.83,207.74,516.57,207.74z M517.13,203.8c0-0.37-0.29-0.58-0.81-0.58
  				h-1.22v1.18h1.14c0.54,0,0.89-0.18,0.89-0.59V203.8z M517.44,206.04c0-0.38-0.28-0.61-0.91-0.61h-1.42v1.23h1.46
  				c0.54,0,0.87-0.19,0.87-0.61V206.04z"
                />
                <path
                  className="st1"
                  d="M519.68,207.74v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H519.68z"
                />
                <path
                  className="st1"
                  d="M527.66,207.84c-1.65,0-2.87-1.27-2.87-2.88v-0.02c0-1.59,1.2-2.89,2.92-2.89c1.05,0,1.69,0.35,2.21,0.86
  				l-0.78,0.9c-0.43-0.39-0.87-0.63-1.43-0.63c-0.94,0-1.62,0.78-1.62,1.74v0.02c0,0.96,0.66,1.76,1.62,1.76
  				c0.64,0,1.03-0.26,1.47-0.66l0.78,0.79C529.38,207.46,528.74,207.84,527.66,207.84z"
                />
                <path
                  className="st1"
                  d="M533.28,207.83c-1.5,0-2.42-0.84-2.42-2.49v-3.2h1.23v3.17c0,0.91,0.46,1.38,1.21,1.38s1.21-0.46,1.21-1.34
  				v-3.21h1.23v3.16C535.73,207,534.78,207.83,533.28,207.83z"
                />
                <path
                  className="st1"
                  d="M536.99,207.74v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H536.99z"
                />
              </g>
              <path
                className="st1"
                d="M552.05,202.93h1.61v-1.36h-1.61V202.93z M552.09,208.91h1.53v-5.39h-1.53V208.91z M552.85,198.24
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C559.85,201.38,556.71,198.24,552.85,198.24z M552.85,211.24c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S556.16,211.24,552.85,211.24z"
              />
            </g>
          </g>
          <g
            id="label-gas-range"
            className="svgTextContainer"
            ref={triggerRefGasRange}
          >
            <g>
              <path
                className="st2"
                d="M411.67,263.55l22-15.23l-24.86,8.59h-56.73c-1.9-1.75-4.43-2.83-7.22-2.83c-5.89,0-10.67,4.78-10.67,10.67
  			s4.78,10.67,10.67,10.67c2.79,0,5.32-1.08,7.22-2.83h59.59V263.55z"
              />
              <g>
                <path
                  className="st1"
                  d="M359.22,267.34c-1.74,0-2.94-1.22-2.94-2.88v-0.02c0-1.59,1.24-2.89,2.93-2.89c1.01,0,1.62,0.27,2.2,0.77
  				l-0.78,0.93c-0.43-0.36-0.82-0.57-1.46-0.57c-0.9,0-1.61,0.79-1.61,1.74v0.02c0,1.02,0.7,1.78,1.7,1.78
  				c0.45,0,0.85-0.11,1.16-0.34v-0.8h-1.24v-1.06h2.43v2.43C361.04,266.94,360.24,267.34,359.22,267.34z"
                />
                <path
                  className="st1"
                  d="M366.96,267.24l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.13l2.4,5.64H366.96z M365.27,263.09
  				l-0.74,1.81h1.49L365.27,263.09z"
                />
                <path
                  className="st1"
                  d="M371.03,267.32c-0.85,0-1.7-0.3-2.38-0.9l0.73-0.87c0.5,0.42,1.03,0.68,1.67,0.68c0.5,0,0.81-0.2,0.81-0.53
  				v-0.02c0-0.31-0.19-0.47-1.13-0.71c-1.13-0.29-1.86-0.6-1.86-1.71v-0.02c0-1.01,0.82-1.69,1.96-1.69c0.82,0,1.51,0.26,2.08,0.71
  				l-0.64,0.93c-0.5-0.34-0.98-0.55-1.46-0.55s-0.72,0.22-0.72,0.49v0.02c0,0.37,0.24,0.49,1.21,0.74c1.14,0.3,1.78,0.7,1.78,1.68
  				v0.02C373.08,266.7,372.24,267.32,371.03,267.32z"
                />
                <path
                  className="st1"
                  d="M379.96,267.24l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H379.96z M379.98,263.56c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V263.56z"
                />
                <path
                  className="st1"
                  d="M386.51,267.24l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.13l2.4,5.64H386.51z M384.81,263.09
  				l-0.74,1.81h1.49L384.81,263.09z"
                />
                <path
                  className="st1"
                  d="M392.59,267.24l-2.71-3.56v3.56h-1.22v-5.6h1.14l2.62,3.45v-3.45h1.22v5.6H392.59z"
                />
                <path
                  className="st1"
                  d="M397.68,267.34c-1.74,0-2.94-1.22-2.94-2.88v-0.02c0-1.59,1.24-2.89,2.93-2.89c1.01,0,1.61,0.27,2.2,0.77
  				l-0.78,0.93c-0.43-0.36-0.82-0.57-1.46-0.57c-0.9,0-1.61,0.79-1.61,1.74v0.02c0,1.02,0.7,1.78,1.7,1.78
  				c0.45,0,0.85-0.11,1.16-0.34v-0.8h-1.24v-1.06h2.43v2.43C399.49,266.94,398.7,267.34,397.68,267.34z"
                />
                <path
                  className="st1"
                  d="M401.25,267.24v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H401.25z"
                />
              </g>
              <path
                className="st1"
                d="M344.05,262.43h1.61v-1.36h-1.61V262.43z M344.09,268.41h1.53v-5.39h-1.53V268.41z M344.85,257.74
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C351.85,260.88,348.71,257.74,344.85,257.74z M344.85,270.74c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S348.16,270.74,344.85,270.74z"
              />
            </g>
          </g>
          <g
            id="label-furnace-boiler"
            className="svgTextContainer"
            ref={triggerRefFurnaceBoiler}
          >
            <g>
              <path
                className="st2"
                d="M378.79,353.48c-2.79,0-5.32,1.08-7.22,2.83h-79.13l-30.26-49.49l23.69,54.1v11.06h85.71
  			c1.9,1.75,4.43,2.83,7.22,2.83c5.89,0,10.67-4.78,10.67-10.67C389.45,358.26,384.68,353.48,378.79,353.48z"
              />
              <g>
                <path
                  className="st1"
                  d="M294.17,362.17v1.19h2.67v1.12h-2.67v2.17h-1.23v-5.6h4.26v1.12H294.17z"
                />
                <path
                  className="st1"
                  d="M300.52,366.74c-1.5,0-2.42-0.84-2.42-2.49v-3.2h1.23v3.17c0,0.91,0.46,1.38,1.21,1.38s1.21-0.46,1.21-1.34
  				v-3.21h1.23v3.16C302.98,365.91,302.03,366.74,300.52,366.74z"
                />
                <path
                  className="st1"
                  d="M307.64,366.65l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H307.64z M307.66,362.97c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V362.97z"
                />
                <path
                  className="st1"
                  d="M313.95,366.65l-2.71-3.56v3.56h-1.21v-5.6h1.14l2.62,3.45v-3.45H315v5.6H313.95z"
                />
                <path
                  className="st1"
                  d="M320.51,366.65L320,365.4h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H320.51z M318.81,362.49l-0.74,1.81
  				h1.49L318.81,362.49z"
                />
                <path
                  className="st1"
                  d="M324.92,366.75c-1.65,0-2.87-1.27-2.87-2.88v-0.02c0-1.59,1.2-2.89,2.92-2.89c1.06,0,1.69,0.35,2.21,0.86
  				l-0.78,0.9c-0.43-0.39-0.87-0.63-1.43-0.63c-0.94,0-1.62,0.78-1.62,1.74v0.02c0,0.96,0.66,1.76,1.62,1.76
  				c0.64,0,1.03-0.26,1.47-0.66l0.78,0.79C326.64,366.36,326,366.75,324.92,366.75z"
                />
                <path
                  className="st1"
                  d="M328.21,366.65v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H328.21z"
                />
                <path
                  className="st1"
                  d="M337.24,360.27l-3.54,7.4h-1.03l3.54-7.4H337.24z"
                />
                <path
                  className="st1"
                  d="M340.47,366.65h-2.66v-5.6h2.6c1.14,0,1.85,0.57,1.85,1.45v0.02c0,0.63-0.34,0.98-0.74,1.21
  				c0.65,0.25,1.05,0.62,1.05,1.38v0.02C342.56,366.14,341.73,366.65,340.47,366.65z M341.03,362.71c0-0.37-0.29-0.58-0.81-0.58H339
  				v1.18h1.13c0.54,0,0.89-0.18,0.89-0.59V362.71z M341.34,364.95c0-0.38-0.28-0.61-0.91-0.61H339v1.23h1.46
  				c0.54,0,0.87-0.19,0.87-0.61V364.95z"
                />
                <path
                  className="st1"
                  d="M346.3,366.75c-1.73,0-2.97-1.29-2.97-2.88v-0.02c0-1.59,1.26-2.89,2.98-2.89c1.73,0,2.97,1.29,2.97,2.88
  				v0.02C349.28,365.44,348.03,366.75,346.3,366.75z M347.99,363.85c0-0.96-0.7-1.76-1.69-1.76c-0.99,0-1.68,0.78-1.68,1.74v0.02
  				c0,0.96,0.7,1.76,1.7,1.76s1.68-0.78,1.68-1.74V363.85z"
                />
                <path
                  className="st1"
                  d="M350.44,366.65v-5.6h1.23v5.6H350.44z"
                />
                <path
                  className="st1"
                  d="M353.07,366.65v-5.6h1.23v4.48h2.79v1.12H353.07z"
                />
                <path
                  className="st1"
                  d="M358.02,366.65v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H358.02z"
                />
                <path
                  className="st1"
                  d="M366.78,366.65l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H366.78z M366.8,362.97c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V362.97z"
                />
              </g>
              <path
                className="st1"
                d="M377.98,361.84h1.61v-1.36h-1.61V361.84z M378.02,367.82h1.53v-5.39h-1.53V367.82z M378.79,357.15
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C385.79,360.29,382.65,357.15,378.79,357.15z M378.79,370.15c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S382.1,370.15,378.79,370.15z"
              />
            </g>
          </g>
          <g
            id="label-water-heater"
            className="svgTextContainer"
            ref={triggerRefWaterHeater}
          >
            <g>
              <path
                className="st2"
                d="M235,301.98l-50.11,54.93h-69.98c-1.9-1.75-4.43-2.83-7.22-2.83c-5.89,0-10.67,4.78-10.67,10.67
  			s4.78,10.67,10.67,10.67c2.79,0,5.32-1.08,7.22-2.83h75.25v-9.4l0.29,0.29L235,301.98z"
              />
              <g>
                <path
                  className="st1"
                  d="M125.7,367.28h-1.07l-1.26-3.66l-1.26,3.66h-1.07l-1.91-5.64h1.32l1.16,3.79l1.26-3.81h1.06l1.26,3.81
  				l1.16-3.79h1.29L125.7,367.28z"
                />
                <path
                  className="st1"
                  d="M131.98,367.24l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H131.98z M130.28,363.09
  				l-0.74,1.81h1.49L130.28,363.09z"
                />
                <path
                  className="st1"
                  d="M135.94,362.78v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H135.94z"
                />
                <path
                  className="st1"
                  d="M138.59,367.24v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H138.59z"
                />
                <path
                  className="st1"
                  d="M147.35,367.24l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H147.35z M147.38,363.56c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V363.56z"
                />
                <path
                  className="st1"
                  d="M155.64,367.24V365h-2.27v2.25h-1.23v-5.6h1.23v2.21h2.27v-2.21h1.23v5.6H155.64z"
                />
                <path
                  className="st1"
                  d="M158.22,367.24v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H158.22z"
                />
                <path
                  className="st1"
                  d="M167.74,367.24l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H167.74z M166.05,363.09
  				l-0.74,1.81h1.49L166.05,363.09z"
                />
                <path
                  className="st1"
                  d="M171.71,362.78v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H171.71z"
                />
                <path
                  className="st1"
                  d="M174.36,367.24v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H174.36z"
                />
                <path
                  className="st1"
                  d="M183.12,367.24l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H183.12z M183.14,363.56c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V363.56z"
                />
              </g>
              <path
                className="st1"
                d="M106.89,362.43h1.61v-1.36h-1.61V362.43z M106.93,368.41h1.53v-5.39h-1.53V368.41z M107.69,357.74
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C114.69,360.88,111.55,357.74,107.69,357.74z M107.69,370.74c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S111,370.74,107.69,370.74z"
              />
            </g>
          </g>
          <g
            id="label-heat-pump-1"
            className="svgTextContainer"
            ref={triggerRefHeatPump1}
          >
            <g>
              <path
                className="st2"
                d="M110.02,303.08c-2.79,0-5.32,1.08-7.22,2.83H52.23l-34.9-44.59l28.19,49.04v11.22h57.28
  			c1.9,1.75,4.43,2.83,7.22,2.83c5.89,0,10.67-4.78,10.67-10.67S115.91,303.08,110.02,303.08z"
              />
              <g>
                <path
                  className="st1"
                  d="M55.61,316.24V314h-2.27v2.25h-1.23v-5.6h1.23v2.21h2.27v-2.21h1.23v5.6H55.61z"
                />
                <path
                  className="st1"
                  d="M58.19,316.24v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H58.19z"
                />
                <path
                  className="st1"
                  d="M67.71,316.24l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H67.71z M66.02,312.09l-0.74,1.81
  				h1.49L66.02,312.09z"
                />
                <path
                  className="st1"
                  d="M71.68,311.78v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H71.68z"
                />
                <path
                  className="st1"
                  d="M78.9,314.57h-0.94v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C81.16,313.89,80.15,314.57,78.9,314.57z M79.92,312.61c0-0.55-0.38-0.85-1-0.85h-0.95v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85
  				V312.61z"
                />
                <path
                  className="st1"
                  d="M84.41,316.33c-1.5,0-2.42-0.84-2.42-2.49v-3.2h1.23v3.17c0,0.91,0.46,1.38,1.21,1.38
  				c0.75,0,1.21-0.46,1.21-1.34v-3.21h1.23v3.16C86.87,315.5,85.92,316.33,84.41,316.33z"
                />
                <path
                  className="st1"
                  d="M92.5,316.24v-3.65l-1.58,2.39h-0.03l-1.56-2.37v3.63h-1.21v-5.6h1.33l1.47,2.37l1.47-2.37h1.33v5.6H92.5z"
                />
                <path
                  className="st1"
                  d="M97.24,314.57H96.3v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C99.5,313.89,98.49,314.57,97.24,314.57z M98.25,312.61c0-0.55-0.38-0.85-1-0.85H96.3v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85
  				V312.61z"
                />
              </g>
              <path
                className="st1"
                d="M109.22,311.43h1.61v-1.36h-1.61V311.43z M109.26,317.41h1.53v-5.39h-1.53V317.41z M110.02,306.74
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C117.02,309.88,113.88,306.74,110.02,306.74z M110.02,319.74c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S113.33,319.74,110.02,319.74z"
              />
            </g>
          </g>
          <g
            id="label-heat-pump-2"
            className="svgTextContainer"
            ref={triggerRefHeatPump2}
          >
            <g>
              <path
                className="st2"
                d="M142.45,71.65L165,125.32l-15.25-54.89V55.98H92.22c-1.9-1.75-4.43-2.83-7.22-2.83
  			c-5.89,0-10.67,4.78-10.67,10.67S79.11,74.48,85,74.48c2.79,0,5.32-1.08,7.22-2.83H142.45z"
              />
              <g>
                <path
                  className="st1"
                  d="M100.17,66.32v-2.25H97.9v2.25h-1.23v-5.6h1.23v2.21h2.27v-2.21h1.23v5.6H100.17z"
                />
                <path
                  className="st1"
                  d="M102.75,66.32v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H102.75z"
                />
                <path
                  className="st1"
                  d="M112.28,66.32l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H112.28z M110.58,62.16l-0.74,1.81
  				h1.49L110.58,62.16z"
                />
                <path
                  className="st1"
                  d="M116.25,61.86v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H116.25z"
                />
                <path
                  className="st1"
                  d="M123.46,64.64h-0.94v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C125.73,63.97,124.72,64.64,123.46,64.64z M124.48,62.68c0-0.55-0.38-0.85-1-0.85h-0.95v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85
  				V62.68z"
                />
                <path
                  className="st1"
                  d="M128.98,66.41c-1.5,0-2.42-0.84-2.42-2.49v-3.2h1.23v3.17c0,0.91,0.46,1.38,1.21,1.38
  				c0.75,0,1.21-0.45,1.21-1.34v-3.21h1.23v3.16C131.43,65.57,130.48,66.41,128.98,66.41z"
                />
                <path
                  className="st1"
                  d="M137.06,66.32v-3.65l-1.58,2.39h-0.03l-1.56-2.37v3.63h-1.21v-5.6h1.33l1.47,2.37l1.47-2.37h1.33v5.6H137.06
  				z"
                />
                <path
                  className="st1"
                  d="M141.8,64.64h-0.94v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C144.06,63.97,143.05,64.64,141.8,64.64z M142.81,62.68c0-0.55-0.38-0.85-1-0.85h-0.95v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85
  				V62.68z"
                />
              </g>
              <path
                className="st1"
                d="M84.2,61.51h1.61v-1.36H84.2V61.51z M84.24,67.49h1.53V62.1h-1.53V67.49z M85,56.82c-3.86,0-7,3.14-7,7
  			c0,3.86,3.14,7,7,7s7-3.14,7-7C92,59.96,88.86,56.82,85,56.82z M85,69.82c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6
  			S88.31,69.82,85,69.82z"
              />
            </g>
          </g>
          <g
            id="label-propane-tank"
            className="svgTextContainer"
            ref={triggerRefPropaneTank}
          >
            <g>
              <path
                className="st2"
                d="M149.02,276.41c-2.79,0-5.32,1.08-7.22,2.83H72.57l-27.22-16.92l21.5,22.48v10.1h74.95
  			c1.9,1.75,4.43,2.83,7.22,2.83c5.89,0,10.67-4.78,10.67-10.67S154.91,276.41,149.02,276.41z"
              />
              <g>
                <path
                  className="st1"
                  d="M74.61,287.9h-0.94v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C76.87,287.23,75.86,287.9,74.61,287.9z M75.62,285.94c0-0.55-0.38-0.85-1-0.85h-0.95v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85
  				V285.94z"
                />
                <path
                  className="st1"
                  d="M81.18,289.58l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H81.18z M81.21,285.89c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V285.89z"
                />
                <path
                  className="st1"
                  d="M86.29,289.67c-1.73,0-2.97-1.29-2.97-2.88v-0.02c0-1.59,1.26-2.89,2.98-2.89s2.97,1.29,2.97,2.88v0.02
  				C89.27,288.37,88.02,289.67,86.29,289.67z M87.98,286.78c0-0.96-0.7-1.76-1.69-1.76s-1.68,0.78-1.68,1.74v0.02
  				c0,0.96,0.7,1.76,1.7,1.76c0.99,0,1.68-0.78,1.68-1.74V286.78z"
                />
                <path
                  className="st1"
                  d="M92.54,287.9H91.6v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C94.8,287.23,93.79,287.9,92.54,287.9z M93.55,285.94c0-0.55-0.38-0.85-1-0.85H91.6v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85V285.94
  				z"
                />
                <path
                  className="st1"
                  d="M99.32,289.58l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H99.32z M97.62,285.42l-0.74,1.81
  				h1.49L97.62,285.42z"
                />
                <path
                  className="st1"
                  d="M105.4,289.58l-2.71-3.56v3.56h-1.21v-5.6h1.14l2.62,3.45v-3.45h1.22v5.6H105.4z"
                />
                <path
                  className="st1"
                  d="M107.79,289.58v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H107.79z"
                />
                <path
                  className="st1"
                  d="M118.09,285.12v4.46h-1.23v-4.46h-1.7v-1.13h4.64v1.13H118.09z"
                />
                <path
                  className="st1"
                  d="M124.18,289.58l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H124.18z M122.49,285.42
  				l-0.74,1.81h1.49L122.49,285.42z"
                />
                <path
                  className="st1"
                  d="M130.26,289.58l-2.71-3.56v3.56h-1.22v-5.6h1.14l2.62,3.45v-3.45h1.22v5.6H130.26z"
                />
                <path
                  className="st1"
                  d="M136.27,289.58l-1.73-2.38l-0.65,0.67v1.71h-1.23v-5.6h1.23v2.45l2.27-2.45h1.49l-2.28,2.37l2.38,3.22
  				H136.27z"
                />
              </g>
              <path
                className="st1"
                d="M148.22,284.77h1.61v-1.36h-1.61V284.77z M148.26,290.75h1.53v-5.39h-1.53V290.75z M149.02,280.08
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C156.02,283.22,152.88,280.08,149.02,280.08z M149.02,293.08c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S152.33,293.08,149.02,293.08z"
              />
            </g>
          </g>
          <g
            id="label-space-heater"
            className="svgTextContainer"
            ref={triggerRefSpaceHeater}
          >
            <g>
              <path
                className="st2"
                d="M101.26,211.66l28.49,8.07l-23.98-14.43v-9.31H32.74c-1.9-1.75-4.43-2.83-7.22-2.83
  			c-5.89,0-10.67,4.78-10.67,10.67s4.78,10.67,10.67,10.67c2.79,0,5.32-1.08,7.22-2.83H101.26z"
              />
              <g>
                <path
                  className="st1"
                  d="M39.18,206.41c-0.85,0-1.7-0.3-2.38-0.9l0.73-0.87c0.5,0.42,1.03,0.68,1.67,0.68c0.5,0,0.81-0.2,0.81-0.53
  				v-0.02c0-0.31-0.19-0.47-1.13-0.71c-1.13-0.29-1.85-0.6-1.85-1.71v-0.02c0-1.01,0.82-1.69,1.96-1.69c0.82,0,1.51,0.26,2.08,0.71
  				l-0.64,0.93c-0.5-0.34-0.98-0.55-1.46-0.55s-0.72,0.22-0.72,0.49v0.02c0,0.37,0.24,0.49,1.21,0.74c1.14,0.3,1.77,0.7,1.77,1.68
  				v0.02C41.24,205.78,40.39,206.41,39.18,206.41z"
                />
                <path
                  className="st1"
                  d="M44.48,204.65h-0.94v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C46.74,203.98,45.73,204.65,44.48,204.65z M45.49,202.69c0-0.55-0.38-0.85-1-0.85h-0.95v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85
  				V202.69z"
                />
                <path
                  className="st1"
                  d="M51.26,206.33l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H51.26z M49.57,202.17l-0.74,1.81
  				h1.49L49.57,202.17z"
                />
                <path
                  className="st1"
                  d="M55.68,206.42c-1.65,0-2.87-1.27-2.87-2.88v-0.02c0-1.59,1.2-2.89,2.92-2.89c1.06,0,1.69,0.35,2.21,0.86
  				l-0.78,0.9c-0.43-0.39-0.87-0.63-1.43-0.63c-0.94,0-1.62,0.78-1.62,1.74v0.02c0,0.96,0.66,1.76,1.62,1.76
  				c0.64,0,1.03-0.26,1.47-0.66l0.78,0.79C57.4,206.04,56.76,206.42,55.68,206.42z"
                />
                <path
                  className="st1"
                  d="M58.96,206.33v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H58.96z"
                />
                <path
                  className="st1"
                  d="M70.22,206.33v-2.25h-2.27v2.25h-1.23v-5.6h1.23v2.21h2.27v-2.21h1.23v5.6H70.22z"
                />
                <path
                  className="st1"
                  d="M72.8,206.33v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H72.8z"
                />
                <path
                  className="st1"
                  d="M82.33,206.33l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H82.33z M80.63,202.17l-0.74,1.81
  				h1.49L80.63,202.17z"
                />
                <path
                  className="st1"
                  d="M86.29,201.87v4.46h-1.23v-4.46h-1.7v-1.13H88v1.13H86.29z"
                />
                <path
                  className="st1"
                  d="M88.94,206.33v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H88.94z"
                />
                <path
                  className="st1"
                  d="M97.7,206.33l-1.2-1.79h-0.97v1.79H94.3v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02c0,0.9-0.49,1.47-1.2,1.74
  				l1.37,2H97.7z M97.73,202.64c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79V202.64z"
                />
              </g>
              <path
                className="st1"
                d="M24.72,201.52h1.61v-1.36h-1.61V201.52z M24.76,207.5h1.53v-5.39h-1.53V207.5z M25.52,196.83
  			c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7C32.52,199.97,29.38,196.83,25.52,196.83z M25.52,209.83c-3.31,0-6-2.69-6-6
  			s2.69-6,6-6s6,2.69,6,6S28.83,209.83,25.52,209.83z"
              />
            </g>
          </g>
          <g
            id="label-fireplace"
            className="svgTextContainer"
            ref={triggerRefFirePlace}
          >
            <g>
              <path
                className="st2"
                d="M80.9,144.32l20.84,16.65L87,138.84v-10.19H30.22c-1.9-1.75-4.43-2.83-7.22-2.83
  			c-5.89,0-10.67,4.78-10.67,10.67c0,5.89,4.78,10.67,10.67,10.67c2.79,0,5.32-1.08,7.22-2.83H80.9z"
              />
              <g>
                <path
                  className="st1"
                  d="M35.9,134.51v1.19h2.67v1.12H35.9v2.17h-1.23v-5.6h4.26v1.12H35.9z"
                />
                <path className="st1" d="M39.98,138.98v-5.6h1.23v5.6H39.98z" />
                <path
                  className="st1"
                  d="M46.01,138.98l-1.2-1.79h-0.97v1.79h-1.23v-5.6h2.56c1.32,0,2.11,0.7,2.11,1.85v0.02
  				c0,0.9-0.49,1.47-1.2,1.74l1.37,2H46.01z M46.03,135.3c0-0.53-0.37-0.8-0.97-0.8h-1.22v1.61h1.25c0.6,0,0.94-0.32,0.94-0.79
  				V135.3z"
                />
                <path
                  className="st1"
                  d="M48.39,138.98v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H48.39z"
                />
                <path
                  className="st1"
                  d="M55.92,137.31h-0.94v1.68h-1.23v-5.6h2.29c1.33,0,2.14,0.79,2.14,1.93v0.02
  				C58.18,136.63,57.17,137.31,55.92,137.31z M56.94,135.35c0-0.55-0.38-0.85-1-0.85h-0.95v1.71h0.98c0.62,0,0.98-0.37,0.98-0.85
  				V135.35z"
                />
                <path
                  className="st1"
                  d="M59.1,138.98v-5.6h1.23v4.48h2.79v1.12H59.1z"
                />
                <path
                  className="st1"
                  d="M68.21,138.98l-0.51-1.25h-2.37l-0.51,1.25h-1.26l2.4-5.64h1.14l2.4,5.64H68.21z M66.52,134.83l-0.74,1.81
  				h1.49L66.52,134.83z"
                />
                <path
                  className="st1"
                  d="M72.63,139.08c-1.65,0-2.87-1.27-2.87-2.88v-0.02c0-1.59,1.2-2.89,2.92-2.89c1.06,0,1.69,0.35,2.21,0.86
  				l-0.78,0.9c-0.43-0.39-0.87-0.63-1.43-0.63c-0.94,0-1.62,0.78-1.62,1.74v0.02c0,0.96,0.66,1.76,1.62,1.76
  				c0.64,0,1.03-0.26,1.47-0.66l0.78,0.79C74.35,138.7,73.71,139.08,72.63,139.08z"
                />
                <path
                  className="st1"
                  d="M75.91,138.98v-5.6h4.22v1.09h-3v1.14h2.64v1.1h-2.64v1.17h3.04v1.1H75.91z"
                />
              </g>
              <path
                className="st1"
                d="M22.2,134.17h1.61v-1.36H22.2V134.17z M22.24,140.15h1.53v-5.39h-1.53V140.15z M23,129.48c-3.86,0-7,3.14-7,7
  			c0,3.86,3.14,7,7,7s7-3.14,7-7C30,132.62,26.86,129.48,23,129.48z M23,142.48c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6
  			S26.31,142.48,23,142.48z"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="propaneHome-tooltip-container">
        <Tooltip triggerRef={triggerRefHeatPump1} id="tooltip-heat-pump">
          <foreignObject x="-20" y="-130" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              Using your propane furnace as a back-up to an electric heat pump
              will reduce your energy costs. Heat pumps lose efficiency when the
              temperature falls below 4°C. A dual fuel system can increase the
              cost-effectiveness of heat pump technology while simultaneously
              improving indoor quality.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefHeatPump2} id="tooltip-heat-pump">
          <foreignObject x="-60" y="20" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              Using your propane furnace as a back-up to an electric heat pump
              will reduce your energy costs. Heat pumps lose efficiency when the
              temperature falls below 4°C. A dual fuel system can increase the
              cost-effectiveness of heat pump technology while simultaneously
              improving indoor quality.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefPropaneTank} id="tooltip-propane-tank">
          <foreignObject x="-50" y="-90" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              Island Petroleum only leases propane tanks. Each Island Petroleum
              tank is delivered and installed by a qualified, licensed, 3rd
              party HVAC technician.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefSpaceHeater} id="tooltip-space-heater">
          <foreignObject x="-30" y="-100" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              Maximize the space in any room with a propane space heater. For
              those hard-to-heat places, a propane space heater occupies little
              space and can even be wall-mounted.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefFirePlace} id="tooltip-fireplace">
          <foreignObject x="-30" y="-90" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              Ambiance meets heat efficiency. Add elegance to any room with an
              easy-to-use, heat efficient propane fireplace.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefWaterHeater} id="tooltip-water-heater">
          <foreignObject x="-100" y="-100" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              Residential water heating is typically the third largest energy
              use in the home. Some propane water heaters offer ‘on-demand’ hot
              water, eliminating stand-by heat loss, which can add up to
              significant net savings.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefFurnaceBoiler}>
          <foreignObject
            x="-110"
            y="-130"
            width="425px"
            height="100%"
            className="tooltip-furnace-boiler"
          >
            <body className="propaneHome-tooltip">
              Using your propane furnace as a back-up to an electric heat pump
              will reduce your energy costs. Heat Pumps lose efficiency when the
              temperature falls below 4°C. A dual fuel system can increase the
              cost-effectiveness of heat pump technology while simultaneously
              improving indoor comfort quality.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefGasRange} id="tooltip-gas-range">
          <foreignObject x="-110" y="-90" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              Discover why culinary experts prefer to cook with a propane stove.
              A gas range cooks food more evenly and produces instantaneous
              heat.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefBBQ} id="tooltip-bbq">
          <foreignObject x="-175" y="-90" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              No separate propane tank required. You can hook your gas grill
              directly to your home’s propane source for ultimate convenience.
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefGenerator} id="tooltip-generator">
          <foreignObject x="-230" y="-90" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              When the power goes out, so does your comfort and heat. Propane
              delivers stand-by energy when you need it most.
            </body>
          </foreignObject>
        </Tooltip>
      </div>
    </>
  );
}

export default SvgWithBackgroundImage;
